<template>
    <custom-table
        :title="$t('pages.module.examManagement.exam.session.user.title')"
        :subTitle="$t('pages.module.examManagement.exam.session.user.subTitle')"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        filterComponentName="ModuleExamSessionUserFilter"
        @action="handleClickAction"
        @changeTable="handleTableChange">
        <template v-slot:user="{ row: record }">
            <router-link :to="'/ecommerce/user?userID=' + record.user.id" class="cursor-pointer text-gray-600 text-hover-primary" target="_blank">
                <span class="fw-bolder">{{ record.user.full_name }}</span>
            </router-link>
            <br>
            <a :href="'mailto:' + record.user.email" class="text-gray-600 text-hover-primary mb-1 cursor-pointer">
                {{ record.user.email }}
            </a>
        </template>
        <template v-slot:examInfo="{ row: record }">
            <router-link :to="'/module/exam-management/exam/save/' + record.exam.id" class="cursor-pointer text-gray-600 text-hover-primary" target="_blank">
                <span class="fw-bolder">{{ resolveDatum(record.exam.translations, this.$root.defaultLanguage.id, 'language_id').title }}</span>
            </router-link>
            <br>
            <span class="text-gray-600 fs-7">{{ $moment(record.session.session_date).format("DD.MM.YYYY") + ' ' + record.session.session_time}}</span>
        </template>
        <template v-slot:recordType="{ row: record }">
            <template v-if="record.reservation_date">
                <span class="badge badge-light-success">{{ $t('pages.module.examManagement.exam.session.user.reservation') }}</span>
                <br>
                <span class="fw-bolder fs-7">{{ $t('pages.module.examManagement.exam.session.user.reservationDate') }}: </span>
                <span class="fs-7">{{ $moment(record.reservation_date).format("DD.MM.YYYY HH:mm")  }}</span>
            </template>
            <span v-else class="badge badge-light-primary">{{ $t('pages.module.examManagement.exam.session.user.direct') }}</span>
        </template>
        <template v-slot:date="{ row: record }">
            <span>{{ record.start_date ? $moment(record.start_date).format("DD.MM.YYYY HH:mm:ss") : "-" }}</span> <br>
            <span>{{ record.ending_date ? $moment(record.ending_date).format("DD.MM.YYYY HH:mm:ss") : "-" }}</span>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <el-popconfirm :title="$t('pages.module.examManagement.exam.session.user.sureFinishSession')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="finishSession(record)" v-if="record.start_date && !record.ending_date">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2" :data-kt-indicator="finishSessionLoading ? 'on' : null" :class="finishSessionLoading && 'pe-none'">
                            <span class="svg-icon svg-icon-3" v-if="!finishSessionLoading">
                                <inline-svg src="/media/icons/duotune/arrows/arr076.svg"/>
                            </span>
                            <span v-if="finishSessionLoading" class="indicator-progress">
                                <span class="spinner-border spinner-border-sm align-middle"></span>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
                <el-popconfirm :title="$t('pages.module.examManagement.exam.session.user.sureRetryFinishSession')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="finishSession(record)" v-if="record.exam && record.exam.without_session && record.ending_date">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2" :data-kt-indicator="finishSessionLoading ? 'on' : null" :class="finishSessionLoading && 'pe-none'">
                            <span class="svg-icon svg-icon-3" v-if="!finishSessionLoading">
                                <inline-svg src="/media/icons/duotune/arrows/arr076.svg"/>
                            </span>
                            <span v-if="finishSessionLoading" class="indicator-progress">
                                <span class="spinner-border spinner-border-sm align-middle"></span>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "user",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.examManagement.exam.session.user.cols.user"),
                    scopedSlots: {customRender: "user"}
                },
                {
                    name: this.$t("pages.module.examManagement.exam.session.user.cols.examInfo"),
                    scopedSlots: {customRender: "examInfo"}
                },
                {
                    name: this.$t("pages.module.examManagement.exam.session.user.cols.recordType"),
                    scopedSlots: {customRender: "recordType"}
                },
                {
                    name: this.$t("pages.module.examManagement.exam.session.user.cols.date"),
                    scopedSlots: {customRender: "date"}
                },
                {
                    name: this.$t("pages.module.examManagement.exam.session.user.cols.answered"),
                    key: "answers_count",
                    class: ""
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                }
            ],
            session: {},
            finishSessionLoading: false,
        }
    },
    computed: {
        table() {
            return this.$store.state.module.exam.session.user.table;
        },
        sessionID(){
            return this.$route.query.sessionID;
        },
        examID(){
            return this.$route.query.examID;
        }
    },
    mounted(){
        this.$store.dispatch('module/exam/session/user/get', {
            page: {},
            filterData: Object.assign(this.$root.getFilterWithUrl({}), {
                session_id: this.sessionID,
                exam_id: this.examID
            })
        });
    },
    methods:{
        /*loadSession() {
            this.axios.get(this.endpoints['module_exam_session'] + '/' + this.sessionID).then((response) => {
                let data = response.data.data;
                this.session = data;
            })
        },*/
        deleteRecord(id){
            this.$store.dispatch("module/exam/session/user/delete", {
                id: id
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("module/exam/session/user/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("module/exam/session/user/get", {
                page: pagination,
                filterData: Object.assign({
                    session_id: this.sessionID,
                    exam_id: this.examID
                }, filterData)
            });
        },
        finishSession(record){
            this.finishSessionLoading = true;

            this.axios.post(this.endpoints['module_exam_session_user_finish'], {
                user_session_id: record.id
            }).then(response => {
                this.onResponse(response.data, () => {
                    this.refreshTable();
                }, () => {
                    this.finishSessionLoading = false;
                });
            }).catch(error => {
                this.onResponseFailure(error.response.data, () => {
                    this.finishSessionLoading = false;
                });
            });
        }
    }
}
</script>

<style scoped>

</style>